import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import logo from "./../../images/moonamar-proizvodnja-u-skladu-s-prirodom.png"
import deLogo from "./../../images/flag.png"
import esLogo from "./../../images/spain.png"
import bsLogo from "./../../images/bosnia-and-herzegovina.png"

const Header = () => (
  <header className="is-hidden-touch">
    <div className="container">
      <div className="_inner">
        <div className="columns">
          <div className="column is-narrow _logoLine">
            <div id="_langMenu" className="dropdown is-hoverable">
              <div className="dropdown-trigger">
                <Link to="/bs/">
                  <figure className="icon">
                    <img src={bsLogo} alt="Language" />
                  </figure>
                </Link>
              </div>
              <div className="dropdown-menu">
                <div className="dropdown-content">
                  <Link to="/" className="dropdown-item animated fadeInUp">
                    Deutsch
                  </Link>
                  <Link to="/es/" className="dropdown-item animated fadeInUp">
                    Español
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="_mainMenu">
              <div className="columns">
                <div className="column is-narrow">
                  <div className="_leftMenu">
                    <Link
                      to="/bs/inspiracija"
                      title="INSPIRACIJA IZ MUDROSTI PRIRODE"
                    >
                      Inspiracija
                    </Link>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div id="_logoMenu" className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <Link
                        to="/bs/"
                        title="MOONAMAR - dodaci prehrani i kozmetika"
                      >
                        <h1 className="_logo title">MOONAMAR</h1>
                      </Link>
                    </div>
                    <div className="dropdown-menu">
                      <div className="dropdown-content">
                        <Link
                          to="/bs/o-nama"
                          title="O NAMA I NAŠIM PROIZVODIMA"
                          className="dropdown-item animated fadeInUp"
                        >
                          O nama
                        </Link>
                        <Link
                          to="/bs/partnerstvo"
                          title="BUDI NAŠ PARTNER !"
                          className="dropdown-item animated delay-1s fadeInUp"
                        >
                          Partnerstvo
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="_rightMenu">
                    <Link
                      to="/bs/#products"
                      title="PRIRODNI DODACI PREHRANI I KOZMETIKA"
                    >
                      Proizvodi
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-narrow _contact">
            <Link to="/bs/kontakt" title="KONTAKTIRAJTE NAS!">
              Kontakt
            </Link>
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
